import { Component, signal } from '@angular/core';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';


@Component({
    selector: 'app-gallery',
    imports: [PhotoGalleryComponent],
    templateUrl: './gallery.component.html',
    styleUrl: './gallery.component.scss'
})
export class GalleryComponent {
  years = [2024, 2023, 2022, 2021, 2019, 2018, 2017, 2016, 2015];
  selectedYear = signal<number>(0);

  selectYear(year: number) {
    this.selectedYear.set(year);
  }

  back() {
    this.selectedYear.set(0);
  }
}
